import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import 'babel-polyfill'

import { DEFAULT_STATE, saveStateToStorage, getInitialState, detectBrowser, browserDetection } from './utils'
//import { przypominajkaInlineInstall } from './utils_przypominajka'
import { fetchFMExtInfo, fetch1zlInfo, setBeneficiary } from './actions'
import Fmext1zlCtaButton from './containers/Fmext1zlCtaButton'
import FmextCtaWidget from './containers/FmextCtaWidget'
import FmextGiveAway1zl from './containers/FmextGiveAway1zl'
//import Navigation from './containers/Navigation'

import PartnerSearchFull from './partner-search/containers/PartnerSearchFull'
import { loadPartnerInitialSearchResults, getPartnerQueryStringChangeHandler } from './partner-search/utils'

import BeneficiarySearchBar from './beneficiary-search/containers/BeneficiarySearchBar'
import BeneficiarySearchFilter from './beneficiary-search/containers/BeneficiarySearchFilters'
import BeneficiarySearchResults from './beneficiary-search/containers/BeneficiarySearchResults'
import BeneficiaryInlineInput from './beneficiary-search/containers/BeneficiaryInlineInput'
import { loadBeneficiaryInitialSearchResults, getBeneficiaryQueryStringChangeHandler } from './beneficiary-search/utils'

import PartnerPage, { DiscountButton } from './containers/PartnerPage'
//import UserAccountBeneficiary from './containers/UserAccountBeneficiary'
import DiscountsBeneficiary from './containers/DiscountsBeneficiary'
//import PartnerBeneficiary from './containers/PartnerBeneficiary'
import E4UBeneficiary from './containers/E4UBeneficiary'
//import FmExtBeneficiary from './containers/FmExtBeneficiary'
import ZhrSearch from './containers/ZhrSearch'
import SchroniskaSearch from './containers/SchroniskaSearch'
import MobileAppBeneficiarySearch from './containers/MobileAppBeneficiarySearch'

import DiscountSearchFull from './discounts/containers/DiscountSearchFull'
import { loadInitialDiscounts, getDiscountQueryStringChangeHandler } from './discounts/utils'

/*
  ====
  1. Setup ground stuff
  ====
*/

// some random js codes rely on detectBrowser being globally available
// window.detectBrowser = detectBrowser
// browserDetection();
// window.browserDetection = browserDetection();

// const store = configureStore(getInitialState());

// save app state to localStorage periodically
// setInterval(function (store) {
//   return function () {
//     return saveStateToStorage(store.getState())
//   }
// }(store), 1000*5)


// store.dispatch(fetch1zlInfo())  // przypominajka widget needs 1zl info
// store.dispatch(fetchFMExtInfo())
// setTimeout(() => store.dispatch(fetchFMExtInfo()), 2000)

// until we manage selected beneficiary only via JS this must fire on each page view
//if (!savedState) {
  // TEST - USUNIETE
  // store.dispatch(fetchUserInfo())
  //.then(() =>
  //  console.log(store.getState())
  //)
//}

// document.addEventListener('userInfo', function(data){
//     store.dispatch(data.detail);
// });

// --- 

// const { beneficiary_id } = queryString.parse(location.search)
// const pathsHandlingBeneficiaryId = [
//     // Insert paths that should be able to set beneficiary from query param here
//     '/okazje/',
// ]

// if (beneficiary_id && pathsHandlingBeneficiaryId.indexOf(location.pathname) > -1) {
//   store.dispatch(setBeneficiary({id: beneficiary_id}))
// }
// ---

// if (!Cookies.get('utm_source')){
//   let { utm_source, utm_medium, utm_campaign } = queryString.parse(location.search)
//   if(utm_source){
//     Cookies.set('utm_source', utm_source);  
//   }
//   if(utm_medium){
//     Cookies.set('utm_medium', utm_medium);  
//   }

//   if(utm_campaign){
//     Cookies.set('utm_campaign', utm_campaign);  
//   }
// }

/*
  ====
  2. Render some components (if found)
  ====
*/

/*
const pageNavContainer = document.getElementById('page-nav-container')
if (pageNavContainer) {
  ReactDOM.render (
    <Provider store={store}>
      <Navigation />
    </Provider>,
    pageNavContainer
  )
}
*/


if (location.pathname === '/sklepy-nie/') {
  // ReactDOM.render (
  //   <Provider store={store}>
  //     <PartnerSearchFull updateQueryString={true}/>
  //   </Provider>,
  //   document.getElementById('shops-search-categories-results')
  // )
  // loadPartnerInitialSearchResults(store)
  // window.onpopstate = getPartnerQueryStringChangeHandler(store)

} 
// else if (location.pathname === '/okazje/') {

  // const discountSearchContainer = document.getElementById('discount-search-full')
  // if (discountSearchContainer) {
  //   ReactDOM.render (
  //     <Provider store={store}>
  //       <DiscountSearchFull updateQueryString={true}/>
  //     </Provider>,
  //     discountSearchContainer
  //   )
  // }

  // const discountsBeneficiaryContainer = document.getElementById('discounts-beneficiary-app');
  // if (discountsBeneficiaryContainer) {
  //   ReactDOM.render (
  //     <Provider store={store}>
  //       <DiscountsBeneficiary goToUrl={discountsBeneficiaryContainer.dataset.goToUrl} />
  //     </Provider>,
  //     discountsBeneficiaryContainer
  //   )
  // }

  // loadInitialDiscounts(store)
  // window.onpopstate = getDiscountQueryStringChangeHandler(store)

// } 
else if (location.pathname.includes('/partner/')) {
  /*
  const partnerBeneficiaryContainer = document.getElementById('partner-beneficiary-app');
  if (partnerBeneficiaryContainer) {
    ReactDOM.render (
      <Provider store={store}>
        <PartnerBeneficiary goToUrl={partnerBeneficiaryContainer.dataset.goToUrl} goToShopUrl={partnerBeneficiaryContainer.dataset.goToShopUrl} />
      </Provider>,
      partnerBeneficiaryContainer
    )
  }
  */
} else if (location.pathname === '/podaruj1zl/' || location.pathname === '/konto/potwierdzono-email/') {
  // const give1zlContainer = document.getElementById('give-away-1zl')
  // if (give1zlContainer) {
  //   ReactDOM.render (
  //     <Provider store={store}>
  //       <FmextGiveAway1zl />
  //     </Provider>,
  //     give1zlContainer
  //   )
  // }
} else if (location.pathname === '/wspieram/') {
  /*
  const searchBarContainer = document.getElementById('search-bar-container'),
        filtersContainer = document.getElementById('org-filters-container'),
        resultsContainer = document.getElementById('org-results-container')

  ReactDOM.render (
    <Provider store={store}>
      <BeneficiarySearchBar updateQueryString={true} searchType={searchBarContainer.dataset.searchType}/>
    </Provider>,
    searchBarContainer
  )
  ReactDOM.render (
    <Provider store={store}>
      <BeneficiarySearchFilter updateQueryString={true}/>
    </Provider>,
    filtersContainer
  )
  ReactDOM.render (
    <Provider store={store}>
      <BeneficiarySearchResults updateQueryString={true}
                                popularOrgs={FM.popularBeneficiaries}
                                latestOrgs={FM.latestBeneficiaries}/>
    </Provider>,
    resultsContainer
  )
  loadBeneficiaryInitialSearchResults(store, {searchType:'organizations'})
  window.onpopstate = getBeneficiaryQueryStringChangeHandler(store)
  */
} else if (location.pathname === '/moje-wsparcie/' || location.pathname === '/moje-wsparcie-sr/') {
  /*
  const useraccountBeneficiaryContainer = document.getElementById('useraccount-beneficiary-app')
  ReactDOM.render (
    <Provider store={store}>
      <UserAccountBeneficiary goToUrl={useraccountBeneficiaryContainer.dataset.goToUrl} />
    </Provider>,
    useraccountBeneficiaryContainer
  )
  */
} else if (location.pathname.includes('materialy')) {
  /*
  const useraccountBeneficiaryContainer = document.getElementById('useraccount-beneficiary-app')
  ReactDOM.render (
    <Provider store={store}>
      <UserAccountBeneficiary goToUrl={useraccountBeneficiaryContainer.dataset.goToUrl} hideDismiss={true} />
    </Provider>,
    useraccountBeneficiaryContainer
  )
  */
} else if (location.pathname.includes('e4upromo')) {
  // const e4uBeneficiaryContainer = document.getElementById('e4u-beneficiary-app')
  // ReactDOM.render (
  //   <Provider store={store}>
  //     <E4UBeneficiary goToUrl={e4uBeneficiaryContainer.dataset.goToUrl} lang={e4uBeneficiaryContainer.dataset.lang} defaultBeneficiaryId={e4uBeneficiaryContainer.dataset.defaultBeneficiaryId} parentId={e4uBeneficiaryContainer.dataset.parentId} hideDismiss={true} />
  //   </Provider>,
  //   e4uBeneficiaryContainer
  // )
} else if (location.pathname.includes('instalacja-rozszerzenia')) {
  /*
  const fmextBeneficiaryContainer = document.getElementById('fmext-beneficiary-app')
  ReactDOM.render (
    <Provider store={store}>
      <FmExtBeneficiary goToUrl={fmextBeneficiaryContainer.dataset.goToUrl} popularBeneficiaries={fmextBeneficiaryContainer.dataset.popularBeneficiaries} numberBeneficiaries={fmextBeneficiaryContainer.dataset.numberBeneficiaries} />
    </Provider>,
    fmextBeneficiaryContainer
  )
  */
} else if (location.pathname.includes('przejdz-do-sklepu/zaloguj')) {
  // const gotopartnerBeneficiaryContainer = document.getElementById('gotopartner-beneficiary-app')
  // if(gotopartnerBeneficiaryContainer){
  //   ReactDOM.render (
  //     <Provider store={store}>
  //       <UserAccountBeneficiary 
  //         goToUrl={gotopartnerBeneficiaryContainer.dataset.action} 
  //         hideDismiss={true} 
  //         partner={gotopartnerBeneficiaryContainer.dataset.partner}
  //         label={gotopartnerBeneficiaryContainer.dataset.label}
  //         customClasses='btn btn-white btn-inline btn-link btn-span-as-link'
  //         loginModal='#partner-redirect-modal'
  //         />
  //     </Provider>,
  //     gotopartnerBeneficiaryContainer
  //   )
  // }
}
// if (location.pathname.indexOf('/partner/') === 0) {
//   const goToPartnerContainer = document.getElementById('partner-page-app')
//   ReactDOM.render (
//     <Provider store={store}>
//       <PartnerPage
//           partner={FM.partner}
//           goToUrl={goToPartnerContainer.dataset.goToUrl}
//           donationsDoubling={goToPartnerContainer.dataset.donationsDoubling} />
//     </Provider>,
//     goToPartnerContainer
//   )

//   // discount buttons:
//   Array.from(document.getElementsByClassName('discount-btn-wrap')).map(btnWrap => {
//     ReactDOM.render (
//       <Provider store={store}>
//         <DiscountButton discountId={btnWrap.dataset.id} classNames={btnWrap.dataset.classes}
//                         text={btnWrap.dataset.text} />
//       </Provider>,
//       btnWrap
//     )
//   });
// }

// if (location.pathname.indexOf('/zhr/') === 0) {
//   const zhrSearchContainer = document.getElementById('zhr-search-app'),
//         zhrOrgId = 4359;
//   ReactDOM.render (
//     <Provider store={store}>
//       <ZhrSearch parentId={zhrOrgId} />
//     </Provider>,
//     zhrSearchContainer
//   )
//   loadBeneficiaryInitialSearchResults(store, {parent:zhrOrgId})
//   window.onpopstate = getBeneficiaryQueryStringChangeHandler(store)
// }
// if (location.pathname.indexOf('/schroniska/') === 0) {
//   const schroniskaSearchContainer = document.getElementById('schroniska-search-app')
//   ReactDOM.render (
//     <Provider store={store}>
//       <SchroniskaSearch parentId={window.schroniskaOrgId} />
//     </Provider>,
//     schroniskaSearchContainer
//   )
//   loadBeneficiaryInitialSearchResults(store, {parent:schroniskaOrgId})
//   window.onpopstate = getBeneficiaryQueryStringChangeHandler(store)
// }
// if (location.pathname.indexOf('/mobileapp-beneficiary/') === 0) {
//   const mobileAppBeneficiarySearchContainer = document.getElementById('mobileapp-beneficiary-search-app')
//   ReactDOM.render (
//     <Provider store={store}>
//       <MobileAppBeneficiarySearch parentId="0" popularOrgs={FM.popularBeneficiaries}/>
//     </Provider>,
//     mobileAppBeneficiarySearchContainer
//   )
//   loadBeneficiaryInitialSearchResults(store, {parent:0})
//   window.onpopstate = getBeneficiaryQueryStringChangeHandler(store)
// }


// if (location.pathname.indexOf('/spolecznosc/dodaj/') === 0) {

//   const communitiesBeneficiaryContainer = document.getElementById('communities-beneficiary-app')
//   ReactDOM.render (
//     <Provider store={store}>
//       <UserAccountBeneficiary 
//         goToUrl={communitiesBeneficiaryContainer.dataset.goToUrl} 
//         customClasses='btn btn-red btn-nouppercase'
//       />
//     </Provider>,
//     communitiesBeneficiaryContainer
//   )

  /*

  const input = document.getElementById('id_beneficiary'),
        inputContainer = input.parentNode,
        kwargs = {name: input.name, id: input.id}

  if (input.form.dataset.inlineBeneficiaryId) {
    kwargs.beneficiary = {
      id: input.form.dataset.inlineBeneficiaryId,
      name: input.form.dataset.inlineBeneficiaryName,
      main_image: input.form.dataset.inlineBeneficiaryThumbnail,
    }
  }

  if (inputContainer) {
    ReactDOM.render (
      <Provider store={store}>
        <BeneficiaryInlineInput {...kwargs} />
      </Provider>,
      inputContainer
    )
  }
  */



// TODO: Remove 1zl cta widget code completely as it will no longer be in use
// const browser = detectBrowser()
// const fmextCtaWidgetNode = document.getElementById('fmext-cta-widget')
// if (fmextCtaWidgetNode && (browser === 'chrome' || browser === 'firefox')) {
//   ReactDOM.render (
//     <Provider store={store}>
//       <FmextCtaWidget />
//     </Provider>,
//     fmextCtaWidgetNode
//   )
// }

// const fmext1zlButtonNode = document.getElementById('btn-1zl-wrap')
// if (fmext1zlButtonNode) {
//   ReactDOM.render (
//     <Provider store={store}>
//       <Fmext1zlCtaButton previewedBeneficiaryId={fmext1zlButtonNode.dataset.beneficiaryId} />
//     </Provider>,
//     fmext1zlButtonNode
//   )
// }



/*
  ====
  3. Other, less important stuff
  ====


console.log("Szukam linków przypominajki...")
// Handle fmext-install links via just events, don't render them using React as there's no need to.
Array.from(document.getElementsByTagName('a')).map(linkNode => {
  if (linkNode.href && linkNode.href.indexOf('/instalacja-rozszerzenia/') > -1) {
    console.log("Link do instalacji rozszrzenia:")
    console.log(linkNode)
    linkNode.addEventListener('click', przypominajkaInlineInstall)
  }
});
*/